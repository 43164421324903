import memoize from "lodash/memoize";
import omit from "lodash/omit";
import uniqBy from "lodash/uniqBy";
import {
    adaptiveTestResultNode,
    KnownMappingNodeKeys,
    MappingNode,
    objectiveBlockedResultNode as blockedObjectiveResultNode,
} from "../interfaces/MappingNode";
import { Subject } from "../interfaces/User";

export const mappingTreeToSubjects = memoize(
    (mappingTree: MappingNode): Subject[] => {
        const nodes = getSubjectNodes(mappingTree);
        const uniqueNodes = uniqBy(nodes, "id");
        return uniqueNodes.map((subject, index) => {
            const token = `subject${index + 1}`;
            return {
                id: subject.id,
                name: subject.value,
                tags: getAllBranchTags(subject),
                colors: {
                    default: `var(--element-${token}-default)`,
                    hover: `var(--element-${token}-hover)`,
                    disabled: `var(--element-${token}-disabled)`,
                },
            };
        });
    }
);

export const getRecommendationsForInitialAdaptiveTest = (
    tree: MappingNode,
    moduleCode: string,
    testStatus: string
): MappingNode | undefined => {
    const sources = getNodesByKnownKey(tree, KnownMappingNodeKeys.Source);
    const recommendationsNode = sources.find(
        (source) => source.value === RECOMMENDATION_NODE_VALUE
    );
    if (!recommendationsNode) return;
    const mod: MappingNode | undefined = recommendationsNode.children.find(
        (node) => node.id === moduleCode
    );
    if (!mod) return;
    const testAI: MappingNode | undefined = mod.children.find(
        (node) => node.id === adaptiveTestResultNode(moduleCode, testStatus)
    );
    if (!testAI) return;
    return testAI;
};

export const getRecommendationsForBlockedObjective = (
    tree: MappingNode,
    moduleCode: string,
    objectiveCode: string
): MappingNode | undefined => {
    console.log(
        "getRecommendationsForBlockedObjective",
        moduleCode,
        objectiveCode
    );
    const sources = getNodesByKnownKey(tree, KnownMappingNodeKeys.Source);
    const recommendationsNode = sources.find(
        (source) => source.value === RECOMMENDATION_NODE_VALUE
    );
    if (!recommendationsNode) return;
    const mod: MappingNode | undefined = recommendationsNode.children.find(
        (node) => node.id === moduleCode
    );
    if (!mod) return;
    const blockedObjective: MappingNode | undefined = mod.children.find(
        (node) => node.id === blockedObjectiveResultNode(objectiveCode)
    );
    if (!blockedObjective) return;
    return blockedObjective;
};

export const getAllBranchTags = (node: MappingNode): string[] => {
    if (node.children.length === 0) return [node.id];
    return [
        node.id,
        ...node.children.flatMap((child) => getAllBranchTags(child)),
    ];
};

const getSubjectNodes = memoize((node: MappingNode) =>
    getNodesByKnownKey(node, KnownMappingNodeKeys.Subject)
);

export const getNodesByKnownKey = (
    node: MappingNode,
    key: KnownMappingNodeKeys
): MappingNode[] => {
    let subjectNodes: MappingNode[] = [];
    if (isKnownKeyNode(node, key)) subjectNodes.push(node);
    node.children?.forEach((node) => {
        subjectNodes = subjectNodes.concat(getNodesByKnownKey(node, key));
    });
    return subjectNodes;
};

const isKnownKeyNode = (
    node: MappingNode,
    key: KnownMappingNodeKeys
): boolean => node.key === key;

export const mappingTreeToSublevels = (
    mappingNode: MappingNode,
    excludeKeys?: string[],
    sublevels: Omit<MappingNode, "children">[][] = []
): Omit<MappingNode, "children">[][] => {
    if (!excludeKeys?.includes(mappingNode.key)) {
        const matchingSublevelIndex = sublevels.findIndex(
            (sublevel) => sublevel[0].key === mappingNode.key
        );
        if (matchingSublevelIndex !== -1)
            sublevels[matchingSublevelIndex].push(
                omit(mappingNode, "children")
            );
        else sublevels.push([omit(mappingNode, "children")]);
    }
    mappingNode.children.forEach((childNode) => {
        mappingTreeToSublevels(childNode, excludeKeys, sublevels);
    });
    return sublevels;
};

export const getMappingNode = (
    nodeId: string,
    mappingTree: MappingNode
): MappingNode | undefined => {
    if (mappingTree.id === nodeId) return mappingTree;
    for (const node of mappingTree.children) {
        const correspondingNode = getMappingNode(nodeId, node);
        if (correspondingNode) return correspondingNode;
    }
    return undefined;
};

export const getNodeIds = (node: MappingNode): string[] => {
    if (!node.children || node.children.length === 0) {
        return [node.id];
    } else {
        return [
            node.id,
            ...node.children.flatMap((childNode) => getNodeIds(childNode)),
        ];
    }
};

const RECOMMENDATION_NODE_VALUE = "Recommandations";
